import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AngebotPicture} from "../angebot";
import {MaterialModule} from "../../material-module";

@Component({
  standalone: true,
  selector: 'app-mueck',
  imports: [
    MaterialModule
  ],
  templateUrl: './mueck.component.html',
  styleUrls: ['./mueck.component.css']
})
export class MueckComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name: "intro", img: "../../../assets/img/referenzen/mueck/mueckIntro.jpg"},
    {name: "6", img: "../../../assets/img/referenzen/mueck/mueck6.jpg"},
    {name: "8", img: "../../../assets/img/referenzen/mueck/mueck8.jpg"},
    {name: "9", img: "../../../assets/img/referenzen/mueck/mueck9.jpg"},
    {name: "5", img: "../../../assets/img/referenzen/mueck/mueck5.jpg"},
    {name: "12", img: "../../../assets/img/referenzen/mueck/mueck12.jpg"},
    {name: "1", img: "../../../assets/img/referenzen/mueck/mueck1.jpg"},
    {name: "3", img: "../../../assets/img/referenzen/mueck/mueck3.jpg"},
    {name: "2", img: "../../../assets/img/referenzen/mueck/mueck2.jpg"},
    {name: "4", img: "../../../assets/img/referenzen/mueck/mueck4.jpg"},
    {name: "7", img: "../../../assets/img/referenzen/mueck/mueck7.jpg"},
    {name: "10", img: "../../../assets/img/referenzen/mueck/mueck10.jpg"},
    {name: "11", img: "../../../assets/img/referenzen/mueck/mueck11.jpg"}
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
