import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavService} from '../service/nav.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import {MaterialModule} from "../material-module";
import {HomePartnerComponent} from "./home-partner/home-partner.component";
import {HomeNewsComponent} from "./home-news/home-news.component";
import {HomeOverviewComponent} from "./home-overview/home-overview.component";
import {HomeKontaktComponent} from "./home-kontakt/home-kontakt.component";
import {HomeIntroComponent} from "./home-intro/home-intro.component";

@Component({
  standalone: true,
  selector: 'app-home',
  imports: [
    MaterialModule,
    HomePartnerComponent,
    HomeNewsComponent,
    HomeOverviewComponent,
    HomeKontaktComponent,
    HomeIntroComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    NavService
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  private sub: any;
  private section: any;

  constructor( private router: Router, private navService: NavService, private route: ActivatedRoute,
    private animateScrollService: NgAnimateScrollService ) {

  }

  onClickSection(sectionId: string) {
    if (sectionId === 'top') {
      sectionId = 'page-top';
    }

    var posTop = document.getElementById(sectionId);
    if ( posTop ) {
      this.animateScrollService.scrollToElement(sectionId, 1500)
    }


  }

  ngOnInit() {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.section = params['section'] || null;
        if (this.section) {
          this.onClickSection(this.section);
        } else {

        }
      });

    //this.navService.setNavButton(""+$(window).scrollTop());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onScrollNav(event : any) {

  }

  onClick() {
    console.log("click");
  }

}
