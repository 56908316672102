<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-4 mt-3">
      <div class="card card-profile">
        <div class="card-image">
          <img src="{{teamScharr.chef.img}}" class="img">
        </div>
        <div class="table">
          <h4 class="card-caption">{{teamScharr.chef.name}}</h4>
          <h6 class="category text-gray">{{teamScharr.chef.position}}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row justify-content-center">
    @for (emplo of teamScharr.employee; track emplo) {
      <div class="col-md-4">
        <div class="card card-profile">
          <div class="card-image">
            <img src="{{emplo.img}}" class="img">
          </div>
          <div class="table">
            <h4 class="card-caption">{{emplo.name}}</h4>
            <h6 class="category text-gray">{{emplo.position}}</h6>
          </div>
        </div>
      </div>
    }
  </div>
</div>
<div class="content-section text-center">
  <div class="team-pic-section">
    <div class="container">
      <div class="col-lg-8 col-lg-offset-2">
      </div>
    </div>
  </div>
</div>
