import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Angebot} from "./angebot";
import {MaterialModule} from "../material-module";

@Component({
  standalone: true,
  selector: 'app-angebot',
  imports: [
    MaterialModule
  ],
  templateUrl: './angebot.component.html',
  styleUrls: ['./angebot.component.css']
})
export class AngebotComponent implements OnInit {

  public angeboteArr: Angebot[] = [
    new Angebot("Ladenbau", "ladenbau", "../../assets/img/referenzen/laden/2_ladenbau_20191110.JPG", "Möchten Sie eine neue Ladeneinrichtung oder die Aktuelle verändern ? Wir sind Ihr Profi für die Gesamtplanungen vom Parkettboden bis hin zum Ladenregalen nach Mass"),
    new Angebot("Messebau", "messebau", "../../assets/img/referenzen/messe/1_messebau_20191110.JPG", "Planen Sie mit Ihrem Unternehmen einen Messeauftritt? Wir Setzen Ihre Ideen um damit Sie einen überzeugenden Auftritt haben."),
    new Angebot("Innenbereich", "innen", "../../assets/img/referenzen/innen/2_innen_20191110.jpg", "Ob Parkettböden, Treppen, Türen, Fenster oder Küche - alles ist möglich. Wir stellen Ihre gewünschten Möbel mit Holz in unserer Schreinerei her."),
    new Angebot("Aussenbereich", "aussen", "../../assets/img/referenzen/aussen/aussen2.jpeg", "Wir fertigen aus Holz Terrassenböden, Umzäunungen, Gartenhaus, Sichtschutzwände oder Velounterstände."),
    new Angebot("Spezialprojekte", "spezial", "../../assets/img/referenzen/spez/1_spezialprojekte_20191110-min.JPG", "Für Spezialaufträge sind wir Ihr kreativer und vielfältiger Partner. Sei es für Turnschuhe auf dem Plakat oder einen originellen Grill aus Chromstahl. Ihre Idee setzen wir in die Tat um. "),
    new Angebot("Visualisierung", "visual", "../../assets/img/referenzen/vis/vis12.jpg", "Damit Sie Ihre Idee vor der Fertigung betrachten können visualisieren wir diese als 3D-Zeichnung"),
    new Angebot("Mückenschutz", "mueck", "../../assets/img/referenzen/mueck/mueck9.jpg", "Lästige Mücken oder Fliegen. Wir haben den richtigen Schutz für Sie.")
  ];

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onNavTo(routVal: string) {
    this.router.navigate([routVal]);
  }

}

