import { Injectable, defineInjectable } from '@angular/core';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class NgAnimateScrollService {
  constructor() {}
  /**
   * @desc scrollToItem Fn scrolls to an items by utilising the animated scroll fn (scrollTo)
   *       and calculating the height of the header to accurately find the item's position.
   * @param {?} elementID
   * @param {?=} duration
   * @param {?=} container the container html native element (or its id), window will be used if not set
   * @return {?}
   */
  scrollToElement(elementID, duration = 750, container) {
    /** @type {?} */
    const item = document.getElementById(elementID);
    if (item) {
      /** @type {?} */
      const itemPos = item.offsetTop;
      if (container) {
        if (typeof container === 'string') {
          container = document.getElementById(container);
        }
        this.scrollTo(container, itemPos, duration, true);
      } else {
        this.scrollTo(window.document, itemPos, duration);
      }
    } else {
      console.error(`Could not find element with the following ID: ${elementID}`);
    }
  }
  /**
   * @desc scrollTo Fn allows scrolling with animation.
   * @private
   * @param {?} element the 'element' that the scroll will happen on.
   * @param {?} to is the location to scroll to.
   * @param {?} duration is the length of the animation.
   * @param {?=} isContainer
   * @return {?}
   */
  scrollTo(element, to, duration, isContainer = false) {
    /** @type {?} */
    const increment = 20;
    /** @type {?} */
    const that = this;
    /** @type {?} */
    let start;
    /** @type {?} */
    let remaining;
    /** @type {?} */
    let currentTime = 0;
    /** @type {?} */
    let animateScroll;
    if (isContainer) {
      // for custom container element
      start = element.scrollTop;
    } else if (element.body.scrollTop > 0) {
      // for chrome
      start = element.body.scrollTop;
    } else if (element.documentElement.scrollTop > 0) {
      // for firefox
      start = element.documentElement.scrollTop;
    } else {
      start = 0;
    }
    remaining = to - start;
    animateScroll =
    /**
    * @return {?}
    */
    () => {
      currentTime += increment;
      /** @type {?} */
      const val = that.easeInOut(currentTime, start, remaining, duration);
      if (isContainer) {
        element.scroll(0, val);
      } else {
        // to allow scroll function on different browsers both chrome and firefox
        top.window.scroll(0, val);
      }
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
  /**
   * @desc easeInOut Fn creates the values necessary to create easeInOut animation.
   * @private
   * @param {?} currentTime is current time.
   * @param {?} startTime is the starting time.
   * @param {?} remainingTime is the time period in the value.
   * @param {?} duration is the duration of the animation
   * @return {?} a number value to scroll to.
   */
  easeInOut(currentTime, startTime, remainingTime, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return remainingTime / 2 * currentTime * currentTime + startTime;
    }
    currentTime--;
    return -remainingTime / 2 * (currentTime * (currentTime - 2) - 1) + startTime;
  }
}
NgAnimateScrollService.decorators = [{
  type: Injectable,
  args: [{
    providedIn: "root"
  }]
}];
/** @nocollapse */
NgAnimateScrollService.ctorParameters = () => [];
/** @nocollapse */
NgAnimateScrollService.ngInjectableDef = defineInjectable({
  factory: function NgAnimateScrollService_Factory() {
    return new NgAnimateScrollService();
  },
  token: NgAnimateScrollService,
  providedIn: "root"
});

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { NgAnimateScrollService };

