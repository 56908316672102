import {Component, OnInit} from '@angular/core';
import {NavService} from '../../service/nav.service';
import {Router} from '@angular/router';
import {AngebotPicture} from "../angebot";
import {MaterialModule} from "../../material-module";

@Component({
  standalone: true,
  selector: 'app-innen',
  imports: [
    MaterialModule
  ],
  templateUrl: './innen.component.html',
  styleUrls: ['./innen.component.css'],
  providers: [
    NavService
  ]
})
export class InnenComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name: "1", img: "../../../assets/img/referenzen/innen/innen33.jpg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen2.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen3.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen4.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen5.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen6.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen7.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen8.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen9.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen10.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen11.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen1.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen13.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen12.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen14.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen15.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen16.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen17.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen18.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen19.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen21.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen20.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen22.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen23.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen24.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen26.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen28.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen32.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen25.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen29.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen30.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen31.jpeg"},
    {name: "1", img: "../../../assets/img/referenzen/innen/innen27.jpeg"}
  ];

  constructor(private router: Router, private navService: NavService) {

  }

  ngOnInit() {

  }

}
