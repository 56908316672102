<div class="scharr-text-yellow">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-3 col-sm-8 container">
        <h2 class="scharr-text-centered scharr-marg-res-md">Kontakt</h2>
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-lg-3 col-md-5 col-sm-12 scharr-text-centered mb-2">
        <a href="tel:+41432778500" class="btn btn-default btn-lg scharr-kontakt-yellow"> <span class="network-name">+41 43 277 85 00</span></a>
      </div>
      <div class="col-lg-3 col-md-5 col-sm-12  scharr-text-centered">
        <a href="mailto:cs@scharrenberg.ch" class="btn btn-default btn-lg scharr-kontakt-yellow"><span
          class="network-name">cs&#64;scharrenberg.ch</span></a>
      </div>
    </div>
    <div class="row justify-content-center mt-3 mb-3">
      <div class="col-12 scharr-text-centered">
        <p class="">Mettlenbachstrasse 19, 8617 Mönchaltorf</p>
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-lg-6 col-md-8 col-sm-10">
        <img class="img-fluid img-centered" src="../../../assets/img/moenchaltdorf.jpeg">
      </div>

    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-lg-6 col-md-8 col-sm-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2705.5441604821517!2d8.719731115921263!3d47.30370697916584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479abad2007fd1f1%3A0x72c8fcf2a671914!2sSchreinerei%20Scharrenberg%20GmbH!5e0!3m2!1sde!2sch!4v1582474305035!5m2!1sde!2sch"
          width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
      </div>
    </div>
  </div>
</div>
