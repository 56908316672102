import { Component, OnInit } from '@angular/core';
import {NavService} from '../../service/nav.service';
import {Router} from '@angular/router';
import {AngebotPicture} from "../angebot";
import {MaterialModule} from "../../material-module";

@Component({
  standalone: true,
  selector: 'app-messebau',
  imports: [
    MaterialModule
  ],
  templateUrl: './messebau.component.html',
  styleUrls: ['./messebau.component.css'],
  providers: [
    NavService
  ]
})
export class MessebauComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name:"25", img:"../../../assets/img/referenzen/messe/1_messebau_20191110.JPG"},
    {name:"24", img:"../../../assets/img/referenzen/messe/2_messebau_20191110.JPG"},
    {name:"23", img:"../../../assets/img/referenzen/messe/3_messebau_20191110.JPG"},
    {name:"22", img:"../../../assets/img/referenzen/messe/4_messebau_20191110.JPG"},
    {name:"21", img:"../../../assets/img/referenzen/messe/5_messebau_20191110.JPG"},
    {name:"20", img:"../../../assets/img/referenzen/messe/6_messebau_20191110.JPG"},
    {name:"19", img:"../../../assets/img/referenzen/messe/7_messebau_20191110.JPG"},
    {name:"18", img:"../../../assets/img/referenzen/messe/8_messebau_20191110.JPG"},
    {name:"17", img:"../../../assets/img/referenzen/messe/9_messebau_20191110.JPG"},
    {name:"16", img:"../../../assets/img/referenzen/messe/10_messebau_20191110.JPG"},
    {name:"15", img:"../../../assets/img/referenzen/messe/11_messebau_20191110.JPG"},
    {name:"14", img:"../../../assets/img/referenzen/messe/12_messebau_20191110.JPG"},
    {name:"13", img:"../../../assets/img/referenzen/messe/13_messebau_20191110.JPG"},
    {name:"12", img:"../../../assets/img/referenzen/messe/14_messebau_20191110.JPG"},
    {name:"11", img:"../../../assets/img/referenzen/messe/15_messebau_20191110.JPG"},
    {name:"10", img:"../../../assets/img/referenzen/messe/16_messebau_20191110.JPG"},
    {name:"9", img:"../../../assets/img/referenzen/messe/17_messebau_20191110.JPG"},
    {name:"8", img:"../../../assets/img/referenzen/messe/messebau8.jpeg"},
    {name:"7", img:"../../../assets/img/referenzen/messe/messebau7.jpeg"},
    {name:"6", img:"../../../assets/img/referenzen/messe/messebau6.jpeg"},
    {name:"5", img:"../../../assets/img/referenzen/messe/messebau5.jpeg"},
    {name:"4", img:"../../../assets/img/referenzen/messe/messebau4.jpeg"},
    {name:"3", img:"../../../assets/img/referenzen/messe/messebau3.jpeg"},
    {name:"2", img:"../../../assets/img/referenzen/messe/messebau2.jpeg"},
    {name:"1", img:"../../../assets/img/referenzen/messe/messebau1.jpeg"}
  ];

  constructor( private router: Router, private navService: NavService ) {

  }

  ngOnInit() {
  }
}
