import {Component, ViewChild} from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import {NavService} from './service/nav.service';
//import {PwaService} from "./service/pwa.service";
import {MatSidenav, MatSidenavModule} from "@angular/material/sidenav";
import {MaterialModule} from "./material-module";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MaterialModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'scharri';

  @ViewChild('snav', {static: false}) sidenav!: MatSidenav;

  constructor( private router: Router,
               private navService: NavService) {

  }

  ngOnInit() {
    this.initUpdatePwa();
  }

  initUpdatePwa() {
    //this.pwaService.checkForUpdate();
  }

  onClickSection(sectionId: string) {
    this.sidenav.close();
    this.router.navigate([sectionId]);
  }

}
