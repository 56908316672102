<div class="container-fluid scharr-section scharr-text-yellow">
  <div class="row justify-content-center">
    <div class="col-lg-3 col-md-3 col-sm-8 container">
      <h2 class="scharr-text-centered scharr-marg-res-md">Kontakt</h2>
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <div class="col-lg-3 col-md-5 col-sm-12 scharr-text-centered mb-2">
      <a href="tel:+41432778500" class="btn btn-default btn-lg scharr-kontakt"> <span class="network-name">+41 43 277 85 00</span></a>
    </div>
    <div class="col-lg-3 col-md-5 col-sm-12  scharr-text-centered">
      <a href="mailto:cs@scharrenberg.ch" class="btn btn-default btn-lg scharr-kontakt"><span class="network-name">cs&#64;scharrenberg.ch</span></a>
    </div>
  </div>
  <div class="row justify-content-center mt-3 mb-3">
    <div class="col-12 scharr-text-centered">
      <p class="">Mettlenbachstrasse 19, 8617 Mönchaltorf</p>
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <div class="col-lg-6 col-md-8 col-sm-10">
      <img class="img-fluid img-centered" src="../../../assets/img/moenchaltdorf.jpeg">
    </div>
  </div>
</div>
