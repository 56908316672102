import { Component, OnInit } from '@angular/core';
import {NavService} from '../../service/nav.service';
import {Router} from '@angular/router';
import {AngebotPicture} from "../angebot";
import {MaterialModule} from "../../material-module";


@Component({
  standalone: true,
  selector: 'app-ladenbau',
  imports: [
    MaterialModule
  ],
  templateUrl: './ladenbau.component.html',
  styleUrls: ['./ladenbau.component.css'],
  providers: [
    NavService
  ]
})
export class LadenbauComponent implements OnInit {

  public picAngebot: AngebotPicture[] = [
    {name: "1", img: "../../../assets/img/referenzen/laden/2_ladenbau_20191110.JPG"},
    {name: "2", img: "../../../assets/img/referenzen/laden/3_ladenbau_20191110.JPG"},
    {name: "3", img: "../../../assets/img/referenzen/laden/4_ladenbau_20191110.JPG"},
    {name: "4", img: "../../../assets/img/referenzen/laden/5_ladenbau_20191110.JPG"},
    {name: "5", img: "../../../assets/img/referenzen/laden/6_ladenbau_20191110.JPG"},
    {name: "6", img: "../../../assets/img/referenzen/laden/7_ladenbau_20191110.JPG"},
    {name: "7", img: "../../../assets/img/referenzen/laden/8_ladenbau_20191110.JPG"},
    {name: "8", img: "../../../assets/img/referenzen/laden/9_ladenbau_20191110.JPG"},
    {name: "9", img: "../../../assets/img/referenzen/laden/10_ladenbau_20191110.JPG"},
    {name: "10", img: "../../../assets/img/referenzen/laden/11_ladenbau_20191110.JPG"},
    {name: "13", img: "../../../assets/img/referenzen/laden/laden1.jpeg"},
    {name: "14", img: "../../../assets/img/referenzen/laden/laden2.jpeg"},
    {name: "11", img: "../../../assets/img/referenzen/laden/12_ladenbau_20191110.JPG"},
    {name: "12", img: "../../../assets/img/referenzen/laden/1_ladenbau_20191110.jpg"}
  ];

  constructor( private router: Router, private navService: NavService ) {

  }

  ngOnInit() {
  }
}
