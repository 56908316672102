import { Component, OnInit } from '@angular/core';
import {MaterialModule} from "../../material-module";

@Component({
  standalone: true,
  selector: 'app-home-partner',
  imports: [
    MaterialModule
  ],
  templateUrl: './home-partner.component.html',
  styleUrls: ['./home-partner.component.css']
})
export class HomePartnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
