import { Component, OnInit } from '@angular/core';
import {MaterialModule} from "../../material-module";

@Component({
  standalone: true,
  selector: 'app-home-kontakt',
  imports: [
    MaterialModule
  ],
  templateUrl: './home-kontakt.component.html',
  styleUrls: ['./home-kontakt.component.css']
})
export class HomeKontaktComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
