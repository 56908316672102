import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MaterialModule} from "../material-module";

@Component({
  standalone: true,
  selector: 'app-schreinerei',
  imports: [
    MaterialModule
  ],
  templateUrl: './schreinerei.component.html',
  styleUrls: ['./schreinerei.component.css']
})
export class SchreinereiComponent implements OnInit {

  constructor(
    private router: Router
) {}

  ngOnInit() {

  }

}
