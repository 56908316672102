<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="">Partner</h2>
      <br>
      <p></p>
    </div>
  </div>
  <div class="row justify-content-center text-center">
    @for (pic of picPartner; track pic) {
      <div class="col-lg-4 col-md-6 col-sm-12 mt-3 mb-3">
        <div class="scharr-card-gall">
          <img src="{{pic.img}}" class="img-fluid"/>
        </div>
      </div>
    }
  </div>
</div>
