import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MaterialModule} from "../../material-module";

@Component({
  standalone: true,
  selector: 'app-home-intro',
  imports: [
    MaterialModule
  ],
  templateUrl: './home-intro.component.html',
  styleUrls: ['./home-intro.component.css']
})
export class HomeIntroComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onNavTo(routVal: any) {
    this.router.navigate([routVal]);
  }

}
